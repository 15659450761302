import { useMutation } from '@tanstack/react-query';
import axiosInstance from '../AxiosInstance';
import { PAYMENTWALL_CHECK_API, PAYMENYWALL_WIDGET_API } from '../constants/apiUrl';
import { ErrorResponse } from 'react-router-dom';

export interface IPaymentWidget {
  creditVoucherId: string;
}
export interface IPaymentWidgetResponse {
  data: {
    widgetUrl: string;
    htmlCode: string;
  };
}

export const postPaymentWidget = (data: IPaymentWidget) =>
  axiosInstance.post(PAYMENYWALL_WIDGET_API, data).then((res) => res.data);

export const usePostPaymentWidget = () => {
  return useMutation((data: IPaymentWidget) => postPaymentWidget(data));
};

export interface IPaymentCheckResponse {
  success: boolean;
  data: IPaymentCheckResponseData;
}

export interface IPaymentCheckResponseData {
  balance?: number;
  purchasedCredit?: number;
  invoiceId: string;
  status: 'PAYMENT_SUCCESS' | 'PAYMENT_ERROR' | 'PAYMENT_PENDING';
}

export interface IPaymentCheck {
  invoiceId: string;
  creditVoucherId: string;
}
export const patchPaymentCheck = (data: IPaymentCheck) =>
  axiosInstance.patch(PAYMENTWALL_CHECK_API, data).then((res) => res.data);

export const usePatchPaymentCheck = ({
  onSuccess,
  onError,
  retry,
  retryDelay,
}: {
  onSuccess: (data: IPaymentCheckResponse) => void;
  onError: (err: ErrorResponse) => void;
  retry?: (failureCount: number, error: ErrorResponse) => boolean;
  retryDelay?: number | undefined;
}) => {
  return useMutation((data: IPaymentCheck) => patchPaymentCheck(data), {
    retry,
    retryDelay,
    onSuccess,
    onError,
  });
};
